
<template>
  <div class="flex vertical full-height">
    <div class="scrollable-container main">
      <div class="page-header">
        <h3>Überblick</h3>
        <div class="actions">          
          <button class="button ghost clear-cache" @click="clearCacheAndRerender">Neu erzeugen</button>        
          <button class="button primary csv-download" @click="downloadCSV">Download CSV</button>
        </div>
      </div>
      <WidgetRow gridTemplateColumns="1fr" flexGrow="1">
        <div class="chart-tiles-row">
          <WidgetContainer>
            <ChartDiagram
              v-if="chartData"
              :chartData="chartData"
              :title="'Anzahl Nutzer (' + totalUsersCount + ')'"
              subtitle="Registrierungen im Zeitverlauf"
              :show-chart-j-s-legend="false"
              @period-changed="setPeriod"
            />
          </WidgetContainer>
          <div class="report-tiles">
            <div class="tile-row">
              <WidgetContainer class="tile active">
                <div class="chart-tile-icon icon">
                  <img src="@/assets/svg/icons/reports/chart_users_icon.svg">
                </div>
                <div class="metric">
                  <span class="value">{{ totalUsersCount }}</span><span class="label">Nutzer</span>
                </div>
              </WidgetContainer>
              <WidgetContainer class="tile">
                <div class="chart-tile-icon icon">
                  <img src="@/assets/svg/icons/reports/konfiguration_icon.svg">
                </div>
                <div class="metric">
                  <span class="value">{{ totalConfigurationsCount }}</span><span class="label">Konfigurationen</span>
                </div>
              </WidgetContainer>
              <WidgetContainer class="tile no-bottom-margin">
                <div class="chart-tile-icon icon">
                  <img src="@/assets/svg/icons/reports/skizze_icon.svg">
                </div>
                <div class="metric">
                  <span class="value">{{  totalScribblesCount }}</span><span class="label">Skizzen</span>
                </div>
              </WidgetContainer>
            </div>
            <div class="tile-row">
              <WidgetContainer class="tile ">
                <div class="chart-tile-icon icon">
                  <img src="@/assets/svg/icons/reports/notiz_icon.svg">
                </div>
                <div class="metric">
                  <span class="value">{{ totalNotesCount }}</span><span class="label">Notizen</span>
                </div>
              </WidgetContainer>
              <WidgetContainer class="tile no-bottom-margin">
                <div class="chart-tile-icon icon">
                  <img src="@/assets/svg/icons/reports/foto_icon.svg">
                </div>
                <div class="metric">
                  <span class="value">{{ totalPhotosCount }}</span><span class="label">Fotos</span>
                </div>
              </WidgetContainer>
            </div>
          </div>
        </div>
      </WidgetRow>
      <h3 class="users">Details</h3>
      <WidgetRow gridTemplateColumns="1fr" flexGrow="1">
        <WidgetContainer class="report-table-widget">
          <table>
            <!-- user_id,username,email,email_verified,created_date,no_projects,no_configurations,no_scribbles,no_notes,newest,oldest,days_usage -->
            <tr class="table-header">
              <th colspan="2" @click="updateSortKey('username')" class="cursor-pointer">Exkludieren</th>
              <th @click="updateSortKey('username')" class="cursor-pointer">Email</th>
              <!-- <th @click="updateSortKey('email_verified')" class="cursor-pointer">Verifiziert</th> -->
              <th :class="sortKey == 'lastUsageFrontend' ? 'active' : ''" @click="updateSortKey('lastUsageFrontend')" class="cursor-pointer">Zuletzt</th>
              <th :class="sortKey == 'created_date' ? 'active' : ''" @click="updateSortKey('created_date')" class="cursor-pointer">Registrierung</th>
              <!-- <th :class="sortKey == 'newest' ? 'active' : ''" @click="updateSortKey('newest')" class="cursor-pointer">Letzte Konfig.</th> -->
              <th :class="sortKey == 'days_usage' ? 'active' : ''" @click="updateSortKey('days_usage')" class="cursor-pointer">Nutzung</th>
              <th :class="sortKey == 'no_projects' ? 'active' : ''" @click="updateSortKey('no_projects')" class="cursor-pointer">Kunden</th>
              <th :class="sortKey == 'no_configurations' ? 'active' : ''" @click="updateSortKey('no_configurations')" class="cursor-pointer">Konfig.</th>
              <th :class="sortKey == 'no_scribbles' ? 'active' : ''" @click="updateSortKey('no_scribbles')" class="cursor-pointer">Skizzen</th>
              <th :class="sortKey == 'no_notes' ? 'active' : ''" @click="updateSortKey('no_notes')" class="cursor-pointer">Notizen</th>
              <th :class="sortKey == 'no_photos' ? 'active' : ''" @click="updateSortKey('no_photos')" class="cursor-pointer">Fotos</th>
            </tr>
            <tr v-for="(row, index) in sortedData" :key="index" :class="excludedUsers.includes(row.username) ? 'excluded' : ''">
              <td><input type="checkbox" class="checkbox" :value="row.username" v-model="excludedUsers" ></td>
              <td><img src="@/assets/svg/icons/reports/avatar.svg" class="icon" /></td>
              <td class="bold">{{ row.username }}</td>
              <!-- <td>{{ row.email_verified == 1 ? 'Ja' : 'Nein' }}</td> -->
              <td>{{ formatDate(new Date(row.lastUsageFrontend != "2000-01-01" ? row.lastUsageFrontend : row.created_date), true) }}</td>
              <td>{{ formatDate(new Date(row.created_date), true) }}</td>
              <!-- <td>{{ formatDate(new Date(row.newest != "2000-01-01" ? row.newest : row.created_date), true) }}</td> -->
              <td>{{ parseInt(row.days_usage) > 0 ? row.days_usage : '1' }}</td>
              <td class="bold">{{ row.no_projects }}</td>
              <td class="bold">{{ row.no_configurations }}</td>
              <td class="bold">{{ row.no_scribbles }}</td>
              <td class="bold">{{ row.no_notes }}</td>
              <td class="bold">{{ row.no_photos }}</td>
            </tr>
            <tr v-if="!sortedData.length">
              <td colspan="11" class="no-data-available">Keine Daten im angegebenen Zeitraum</td>
            </tr>
          </table>
        </WidgetContainer>
      </WidgetRow>
    </div>
  </div>
</template>

<script>

import ChartDiagram from '../components/reporting/ChartDiagram.vue';
import WidgetContainer from '../components/widgets/WidgetContainer.vue'
import WidgetRow from "../components/widgets/WidgetRow.vue"
import axios from 'axios';
import { parse } from 'csv-parse/browser/esm/sync';
import UiMixin from '../components/mixins/UiMixin.vue';
import { useUI } from '@profineberater/configurator-clientlib'

const baseURL = process.env.VUE_APP_SERVER_CONFIGURATOR_URL

export default {
  name: 'ReportPage',
  mixins: [UiMixin],
  components: {
    ChartDiagram,
    WidgetContainer,
    WidgetRow,
  },
  data() {
    return {
      csvData: '',
      parseError: null,
      sortKey: 'lastUsageFrontend',
      sortDirection: 'desc',
      availableReports: [],
      currentReportDate: null,
      startDate: null,
      endDate: null,
      excludedUsers: []
    };
  },
  computed: {

    parsedCsvData() {
      if (!this.csvData) return [];

      try {
        const records = parse(this.csvData, {
          columns: true,
          skip_empty_lines: true
        });

        // Zuerst nach gültigen Usernamen filtern
        let filteredRecords = records.filter(r => r.username && r.username.trim() !== '');
        
        let startDate = this.startDate;
        let endDate = this.endDate;
        // Wenn startDate gesetzt aber endDate nicht, endDate auf heute setzen
        if (startDate && !endDate) {
          endDate = new Date();
        }
        // Wenn endDate gesetzt aber startDate nicht, startDate auf 2000-01-01 setzen
        if (!startDate && endDate) {
          startDate = new Date('2000-01-01');
        }


        // Wenn startDate und endDate gesetzt sind, nur Records innerhalb dieses Zeitraums berücksichtigen
        if (startDate && endDate) {
          filteredRecords = filteredRecords.filter(r => {
            // Stelle sicher, dass r.created_date existiert und trimme den String
            if (!r.created_date) return false;
            const createdDate = new Date(r.created_date.trim());
            // Falls createdDate ungültig ist, den Record verwerfen
            if (isNaN(createdDate)) return false;
            return createdDate >= startDate && createdDate <= endDate;
          });
        }
        // Anschließend wird das Mapping durchgeführt
        return filteredRecords.map(r => {
          return {
            ...r,
            lastUsageFrontend: (r.lastUsageFrontend && r.lastUsageFrontend !== "2000-01-01")
                                  ? r.lastUsageFrontend
                                  : (r.newest || r.created_date || '2000-01-01'),
            newest: r.newest || '2000-01-01'
          };
        });
      } catch (error) {
        console.error("Fehler beim Parsen der CSV-Daten:", error);
        return [];
      }
    },

    fileredCsvData() {
      return this.parsedCsvData.filter(user => !this.excludedUsers.includes(user.username));
    },

    dateRangeString() {
      let fromDate = this.parsedCsvData?.[0]?.created_date;
      let toDate = this.parsedCsvData?.[this.parsedCsvData?.length - 1]?.created_date;
      let formattedFromDate = fromDate ? new Date(fromDate).toLocaleDateString('de-DE') : '';
      let formattedToDate = toDate ? new Date(toDate).toLocaleDateString('de-DE') : '';
      return `${formattedFromDate} - ${formattedToDate}`;
    },
    totalScribblesCount() {
      return this.fileredCsvData.reduce((total, user) => {
        return total + parseInt(user.no_scribbles);
      }, 0)
    },
    totalUsersCount() {
      return this.fileredCsvData.length;
    },
    totalNotesCount() {
      return this.fileredCsvData.reduce((total, user) => {
        return total + parseInt(user.no_notes);
      }, 0)
    },
    totalPhotosCount() {
      return this.fileredCsvData.reduce((total, user) => {
        return total + parseInt(user.no_photos);
      }, 0)
    },
    totalConfigurationsCount() {
      return this.fileredCsvData.reduce((total, user) => {
        return total + parseInt(user.no_configurations);
      }, 0)
    },
    totalProjectsCount() {
      return this.fileredCsvData.reduce((total, user) => {
        return total + parseInt(user.no_projects);
      }, 0)
    },
    chartData() {
      let userData = this.processUserData;
      return {
        labels: (userData.dates || []).map(date => date.toDateString('de-DE')),
        datasets: [{
          label: 'Anmeldungen',
          data: userData.userCount || [],
          fill: true,
          borderColor: "#1682F3",
          tension: 0.1
        }]
      }
    },
    processUserData() {
      // Convert created_date to Date objects and sort them
      let sortedDates = this.fileredCsvData.map(user => new Date(user.created_date)).sort((a, b) => a - b);

      let dates = [];
      let userCount = [];
      let count = 0;

      // Iterate through sorted dates
      sortedDates.forEach((date, index) => {
          // Increment count for each user
          count++;

          // Check if this date is different from the next date or it's the last date
          if (index === sortedDates.length - 1 || date.toDateString() !== sortedDates[index + 1].toDateString()) {
            if (date != null) {
              dates.push(date);
              userCount.push(count);
            }
          }
      });
      return { dates, userCount };
    },
    sortedData() {
      return this.parsedCsvData.slice().sort((a, b) => {
        // take sort direction into account
        if (this.sortDirection === 'asc') {
          [a, b] = [b, a];
        }
        // If the sortKey is 'created_date', sort by Date
        if (this.sortKey === 'created_date') {
          return new Date(b.created_date) - new Date(a.created_date);
        }
        if (this.sortKey === 'newest') {
          return new Date(b.newest) - new Date(a.newest);
        }
        if (this.sortKey === 'lastUsageFrontend') {
          return new Date(b.lastUsageFrontend) - new Date(a.lastUsageFrontend);
        }
        if (this.sortKey === 'lastUsageAdmin') {
          return new Date(b.lastUsageAdmin) - new Date(a.lastUsageAdmin);
        }

        // Default to descending order for other keys
        return parseInt(b[this.sortKey]) < parseInt(a[this.sortKey]) ? -1 : 1;
      });
    }
  },
  mounted() {
    this.fetchCsv();
    this.loadExcludedUsers();
  },

  methods: {
    loadExcludedUsers() {
      const stored = localStorage.getItem('excludedUsers');
      if (stored) {
        try {
          this.excludedUsers = JSON.parse(stored);
        } catch (error) {
          console.error('Fehler beim Parsen von excludedUsers aus localStorage:', error);
        }
      }      
    },
    clearCacheAndRerender() {      
      this.fetchCsv(true);
    },
    async fetchCsv(clearCache = false) {
      if (clearCache) {
        this.csvData = null;
      }

      setTimeout( () => {
        if (!this.csvData) {
          useUI(this.$store).blockUI({ loading: true });
        }
      }, 150);
      
      try {
        let url = `${baseURL}/api/v1/admin/reporting/userdata`;
        if (clearCache) {
          url += '?clearCache=true';
        }
        const response = await axios({
          url: url,
          method: 'get',
          headers: { 'Authorization': `Bearer ${window.$keycloak.token}` }
        })
        this.csvData = response.data;
        useUI(this.$store).unblockUI();
      } catch (error) {
        console.error('Error fetching CSV:', error);
        this.parseError = error;
        useUI(this.$store).unblockUI();
      }
    },
    downloadCSV() {
      const blob = new Blob([this.csvData], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      // set filename to framework-report-<date>.csv
      a.download = `framework-report-${new Date().toISOString().split('T')[0]}.csv`;      
      a.click();
      window.URL.revokeObjectURL(url);
    },
    setPeriod(period) {
      this.startDate = period.startDate;
      this.endDate = period.endDate;
    },
    updateSortKey(key) {
      if (this.sortKey === key) {
        this.sortDirection = this.sortDirection === 'desc' ? 'asc' : 'desc';
      }
      this.sortKey = key;
    },
    formatReportDate(dateString) {
      let date = new Date(dateString)
      return this.formatDate(date)
    },
  },
  watch: {
    excludedUsers: {
      handler(newVal) {
        localStorage.setItem('excludedUsers', JSON.stringify(newVal));
      },
      deep: true
    }
  }
}
</script>

<style scoped>
.chart-tiles-row {
  display: flex;
  flex-direction: row;
}

h3.users {
  margin-top: 40px;
}

.report-tiles {
  display: flex;
  flex-direction: row;
  margin-left: 20px;
}
.tile {
  flex-grow: 1;
  height: 204px;
  width: 199px;
  margin-right: 20px;
  margin-bottom: 20px;
  position: relative;
}

.tile.no-bottom-margin {
  margin-bottom: 0;
}

.tile.active {
  background: linear-gradient(to bottom, #2812FF, #59BCFC);
  color: #fff;
}
.tile.active span.value, .tile.active span.label {
  color: #fff;
}
.metric {
  position: absolute;
  bottom: 20px;
  left: 20px;
  text-align: left;
}

.tile .icon {
  position: absolute;
  top: 20px;
  right: 20px;
}
span.value {
  font-size: 36px;
  font-weight: 500;
  display: block;
}

span.label {
  font-size: 16px;
  font-weight: 500;
  color: var(--c-deep-grey);
}

.page-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.page-header ul {
  display: flex;
  flex-direction: row;
  list-style: none;
  padding: 0;
  margin: 0 20px 0 0;
}

.page-header ul li:last-child {
  margin-right: 0;
}

.button.tab {
  background-color: #e9ebf2;
}

.button.tab.active {
  color: #fff;
  background-color: var(--primary-color);
}

table {
  text-align: left;
}

table {
  transform: translateY(-50px);
}
td {
  vertical-align: middle;
  color: var(--text-color);
  font-weight: 400;
  border-bottom: 1px solid #EDEFF4;
  padding: 10px 0 10px 0;
}
th {
  padding-bottom: 20px;
  text-transform: uppercase;
  color: var(--text-color);
  font-weight: 400;
  font-size: 10px;
  letter-spacing: 0.5px;
}

h3 {
  text-align: left;
}

td.bold {
  font-weight: 600;
  color: var(--darkest-text-color);
}
tr.excluded, tr.excluded td.bold {
  color: var(--text-color);
  text-decoration: line-through;
}

th.active {
  color: var(--darkest-text-color);
}
:deep(.report-table-widget) {
  margin-top: 50px;
}
:deep(.chart-tile-icon) {
  color: var(--primary-color);
}

td.no-data-available {
  text-align: center;
  border: none;
  padding-top: 40px;
}
button.clear-cache {
  height: 30px;
  margin-right: 20px;
}
button.csv-download {
  height: 34px;
  margin-right: 20px;
}
</style>